<template>
  <div class="about">
    <div class="row about-intro-container mb-4">
      <div class="col-md-6 p-0">
        <img
          src="@/assets/images/about.jpg"
          class="w-100 about-pic"
          alt="Picture of me in front a deer"
        />
      </div>
      <div class="col-md-6 about-intro">
        <h4 class="mb-4">Broderick Lemke</h4>
        <h2>
          Some tagline here, wow isn't that witty? There's some more text even!
        </h2>
      </div>
    </div>

    <h2 class="timeline-header">What Have I Been Up To?</h2>
    <ul class="timeline">
      <li class="primary">
        <div class="timeline-container">
          <div class="timeline-title h3">2009 - 2014</div>
          <div class="timeline-body row w-100 mx-auto">
            <div class="col-md-3">
              <img
                src="@/assets/images/tobby/tobbySquare2.jpg"
                alt="Tobby near a tower"
                class="hoverPic w-100 m-auto rounded-circle"
              />
            </div>
            <div class="col-md-9">Description</div>
          </div>
        </div>
      </li>
      <li class="secondary">
        <div class="timeline-container">
          <div class="timeline-title h3">2014 - 2017</div>
          <div class="timeline-body row w-100 mx-auto">
            <div class="col-md-3">
              <img
                src="@/assets/images/musicnotesicon.png"
                alt="Music Notes Icon"
                class="hoverPic w-100 m-auto rounded-circle"
              />
            </div>
            <div class="col-md-9">Description</div>
          </div>
        </div>
      </li>
      <li class="info">
        <div class="timeline-container">
          <div class="timeline-title h3">2018 - 2019</div>
          <div class="timeline-body row w-100 mx-auto">
            <div class="col-md-3">
              <img
                src="@/assets/images/binary.gif"
                alt="Tobby near a tower"
                class="hoverPic w-100 m-auto rounded-circle"
              />
            </div>
            <div class="col-md-9">Description</div>
          </div>
        </div>
      </li>
      <li class="primary">
        <div class="timeline-container">
          <div class="timeline-title h3">2019</div>
          <div class="timeline-body row w-100 mx-auto">
            <div class="col-md-3">
              <img
                src="@/assets/images/filedelete.gif"
                alt="A folder being deleted"
                class="hoverPic w-100 m-auto rounded-circle"
              />
            </div>
            <div class="col-md-9">Description</div>
          </div>
        </div>
      </li>
      <li class="secondary">
        <div class="timeline-container">
          <div class="timeline-title h3">2021</div>
          <div class="timeline-body row w-100 mx-auto">
            <div class="col-md-3">
              <img
                src="@/assets/images/laugh.gif"
                alt="Tobby near a tower"
                class="hoverPic w-100 m-auto rounded-circle"
              />
            </div>
            <div class="col-md-9">
              Description <br /><br /><br />Test <br /><br /><br />
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped></style>
